
import RelatedArticleList from '@/modules/article/components/related-article-list.vue';
import 'hooper/dist/hooper.css';
import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n';
import { ArticleModel } from '@/modules/article/article-model';
import moment from 'moment';

export default {
  data() {
    return {
      slide: 0,
      likedArticle: false,
      isBookmarked: false,
    };
  },
  methods: {
    ...mapActions({
      doFind: 'article/view/doFind',
      doFetchLikes: 'article/view/doFetchLikes',
      doFetchUserLike: 'article/view/doFetchUserLike',
      doFetchViewers: 'article/view/doFetchViewers',
      doLike: 'article/view/doLike',
    }),
    rowPresenter(row, fieldName) {
      if (this.isRTL) {
        return row[fieldName]['ar'];
      }
      return row[fieldName]['en'];
    },
    async doLikeArticle() {
      await this.doLike({
        articleId: this.record.id,
        userId: this.anonymousToken,
      });
      this.likedArticle = !this.likedArticle;
    },
    doBookmarkArticle() {
      this.isBookmarked = !this.isBookmarked;
    },
    presenterTitle(row) {
      if (!this.isRTL) {
        const val = ArticleModel.presenter(row, 'title');
        return val ? val['en'] : 'ـــــــ';
      } else {
        const val = ArticleModel.presenter(row, 'title');
        return val ? val['ar'] : 'ـــــــ';
      }
    },
    presenterDescription(row) {
      if (!this.isRTL) {
        const val = ArticleModel.presenter(
          row,
          'description',
        );
        return val ? val['en'].split('\n') : 'ـــــــ';
      } else {
        const val = ArticleModel.presenter(
          row,
          'description',
        );
        //   this.paragraphs = this.article.body.split("\n")
        return val ? val['ar'].split('\n') : 'ـــــــ';
      }
    },
    presenterTime(row, fieldName) {
      //   let now = moment().format('YYYY/MM/DD');
      let date = ArticleModel.presenter(row, fieldName);
      console.log(date);
      //   let date =  `${now} ${time}`;
      // return moment(date).locale(this.currentLanguageCode).format("hh:mm a");
      return date
        ? moment(date.seconds)
          .locale(this.currentLanguageCode)
          .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
    },
    displayDate(date) {
      return date
        ? moment(date)
          .locale(this.currentLanguageCode)
          .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
      // return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
    },

    async goToRelatedArticleArticle(recordId) {
      console.log('goToRelatedArticleArticle', recordId);
      await this.doFind({ id: recordId });
      this.$router.push({
        params: {
          id: recordId,
        },
      });
    },
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
      currentLanguageCode: 'layout/currentLanguageCode',
      // article:'article/list/article'
      record: 'article/view/record',
      loading: 'article/view/loading',
      likes: 'article/view/likes',
      isLiked: 'article/view/isLiked',
      anonymousToken: 'layout/anonymousToken',
      viewer: 'article/view/viewer',
    }),
    isRTL() {
      // return getLanguageCode() == 'ar'
      // return localStorage.getItem('language') == 'ar'
      return i18n.locale == 'ar';
    },
  },
  components: {
    // Hooper,
    // Slide,
    // HooperPagination,
    // HooperNavigation,
    [RelatedArticleList.name]: RelatedArticleList,
  },
  async created() {
    console.log('params', this.$route.params.id);
    await this.doFind({
      articleId: this.$route.params.id,
      userId: this.anonymousToken,
    });
    console.log('RECORD', this.record);

    //   this.record = this.rows.filter(el => el.id == this.$route.params.id)[0]
    //   this.record = this.article.filter(el => el.id == this.$route.params.id)[0]
    //   console.log('this.record',this.article);
  },
};
