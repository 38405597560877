
import i18n from '@/vueI18n'
import { mapGetters,mapActions } from 'vuex'
import moment from 'moment';
import { ArticleModel } from '@/modules/article/article-model';

// import { routerAsync } from '@/app-module';

export default {
    name:'app-related-article-list',
    data(){
      return{
        thumbStyle: {
        // right: "2px",
        borderRadius: "9px",
        backgroundColor: "#A8000F",
        width: "5px",
        opacity: 1,
      },
        //  article:[
        //       {
        //     id:'1',
        //           img:'/images/farag-amer1.png',
        //           date:'الخميس 04/مارس/2021 - 12:43 م',
        //           title:{
        //             ar:'فرج عامر يضع شرطا وحيدا لرحيل الهاني سليمان إلى الأهلي',
        //             en:"Faraj Amer sets a single condition for the departure of Hani Suleiman to Al-Ahly"
        //           }
        //       },
        //       {
        //            id:'2',
        //           img:'/images/farag-amer2.png',
        //           date:'الأربعاء، 03 مارس 2021 - 10:40',
        //           title:{
        //           ar:'فرج عامر: اتحاد الكرة يتلذذ بتعذيب بعض الأندية.. وأخطاء التحكيم ضد سموحة مقصودة',
        //           en:"The Football Association takes pleasure in torturing some clubs ... and arbitration errors against Smouha are intentional"

        //           }
        //       },
        //       {
        //         id:'3',
        //           img:'/images/ahmed-nagy.png',
        //           date:'الأربعاء، 03 مارس 2021 - 14:07',
        //           title:{
        //           ar:'أحمد ناجي: الكثيرون لاحظوا تغير الهاني سليمان.. عبد المنصف يجبر الجميع على الإشادة به',
        //           en:'Ahmed Naji: A lot of people have noticed the change of El Hani Suleiman ... Abdel Monsef forces everyone to praise him'

        //           }
        //       },{
        //         id:'4',
        //         img:'/images/mostafa-fathi.png',
        //         date:'الثلاثاء، 02 مارس 2021 - 18:05',
        //         title:{
        //           ar:'مصطفى فتحي: أحمد سامي يعطيني حرية كبيرة مع سموحة',
        //           en:'Mustafa Fathy: Ahmed Sami gives me great freedom with Smouha'
        //         }
        //       },

        //        {
        //            id:'5',
        //           img:'/images/hossam-hassan.png',
        //           date:'السبت، 27 فبراير 2021 - 10:54',
        //           title:{
        //             ar:'فرج عامر: حسام حسن سينتقل إلى نادٍ مصري بالقيمة التي أردناها',
        //             en:"Faraj Amer: Hossam Hassan will move to an Egyptian club with the value we wanted'"
        //           }
                  
        //       },
        //       {
        //         id:'6',
        //         date:"‏١ مارس‏، الساعة ‏١٠:٤٦ ص‏  ",
        //         img:"/images/basket-article.png",
        //         title:{
        //           ar:"سلة سموحة تحقق الفوز علي المصرية للاتصالات في بطولة دوري السوبر بنتيجة 71/69",
        //           en:"Smouha basket achieves victory over Telecom Egypt in the Super League championship with a score of 71/69"
        //         }
        //       }
           
        //   ]
      }
    },
    methods:{
      ...mapActions({
        doFetch:'article/list/doFetch',
        setMountedTable: 'article/list/setMountedTable'
      }),
      rowPresenter(row,fieldName){
        // if(!this.is_screen_ipad_pro){
            if(this.isRTL){
              return row[fieldName]['ar'].slice(0,60) + '...'
          }
          return row[fieldName]['en'].slice(0,60) + '...'
        // }
        // else{
        //   if(this.isRTL){
        //       return row[fieldName]['ar'].slice(0,60) + '...'
        //   }
        //   return row[fieldName]['en'].slice(0,60) + '...'
        // }
        
      },
      presenterTitle(row){
      if(!this.isRTL){
        const val = ArticleModel.presenter(row, 'title_en');
        return val ? val['en'] : 'ـــــــ';
      }
      else{
        const val = ArticleModel.presenter(row, 'title');
        return val ? val : 'ـــــــ';
      }
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = ArticleModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      // return moment(date).locale(this.currentLanguageCode).format("hh:mm a"); 
      return date ? moment(date).locale(this.currentLanguageCode).format("dddd D MMM YYYY hh:mm A") : 'ـــ';

    },
      displayDate(date) {
      return date ? moment(date).locale(this.currentLanguageCode).format("dddd D MMM YYYY hh:mm A") : 'ـــ';
      // return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
    },
      goToArticle(id){
        console.log(id);
        // routerAsync().push(`/article/${id}`)
        console.log('route',this.$route);
        this.$router.replace({
          name:'article-view',
          params: { id}
        })
        document.location.reload(false);
//         .catch(err => {
//   console.log(err);
// })
//          this.$router.replace({
//   path: '/article',
//   params:{
//     id
//   }
//   // query: {
//   //   term: 'lorem',
//   //   sort: 'alphabetical'
//   // }
// })
// .catch(err => {
//   console.log(err);
// })
      }
    },
    async created(){
      if(!this.mountedTable){
        await this.doFetch()
        this.setMountedTable(true)
      }
    },
    computed:{
      isRTL() {
        return i18n.locale == 'ar'
      },
      ...mapGetters({
        is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
        article:'article/list/article',
        mountedTable:'article/list/mountedTable',
        loading:'article/list/loading',
        currentLanguageCode: 'layout/currentLanguageCode',
      })
    }
   
    

}
